<template>
	<div class="flex flex-wrap m-auto max-w-7xl">
		<div v-for="artist in artistArray" class="p-2 w-full lg:w-1/3">
			<div class="card card-compact bg-base-300 rounded-lg shadow-xl">
				<figure>
					<img
						class="w-full"
						v-lazy="artist.imageSrc"
						:alt="artist.name"
					/>
				</figure>
				<div class="card-body">
					<h2 class="card-title text-accent font-reggae">
						{{ artist.name }}
					</h2>
					<div class="card-actions justify-end">
						<a
							v-if="artist.websiteLink"
							class="btn btn-primary btn-sm"
							:href="artist.websiteLink"
							target="_blank"
							>Web Site</a
						>
						<a
							v-if="artist.twitterLink"
							class="btn btn-secondary btn-sm"
							:href="artist.twitterLink"
							target="_blank"
							>Twitter</a
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			artistArray: [
				{
					name: "打首獄門同好会",
					imageSrc: require("@/assets/images/artist/uchikubi.jpg"),
					websiteLink: "http://www.uchikubi.com/",
					twitterLink: "https://twitter.com/uchikubigokumon",
				},
				{
					name: "巨乳まんだら王国。",
					imageSrc: require("@/assets/images/artist/kyoman.jpg"),
					websiteLink: "https://www.kyoman.com/",
					twitterLink: null,
				},
				{
					name: "鬼龍院翔",
					imageSrc: require("@/assets/images/artist/KiryuinSho.jpg"),
					websiteLink: null,
					twitterLink: "https://twitter.com/kiryuintw",
				},
				{
					name: "THE冠",
					imageSrc: require("@/assets/images/artist/THEkanmuri.jpg"),
					websiteLink: "http://thekanmuri.jp/",
					twitterLink: null,
				},
				{
					name: "バックドロップシンデレラ",
					imageSrc: require("@/assets/images/artist/bakushin.jpg"),
					websiteLink: "http://backdropc.com/",
					twitterLink: "https://twitter.com/bdcinderella",
				},
			],
		};
	},
};
</script>
