<template>
	<div class="flex flex-wrap items-center justify-between">
	  <div class="w-full lg:w-8/12">
		<p>
		  <span class="text-primary">{{ propsTitle }}</span>
		  <br />
		  受付期間:<span
			class="lg:ml-3"
			:class="{
			  'text-gray-700': !isPeriod(propsStart, propsEnd),
			}"
			>{{ propsPeriod }}</span
		  >
		  <p v-if="propsLawsonLink !== ''" 
		  :class="{'text-gray-700':propsLawsonLink =='----'}">
		  Lコード: {{ propsLawsonCode }}
		</p>
		  <p v-if="propsPiaLink !== ''" :class="{'text-gray-700':propsLawsonLink =='----'}">
			Pコード: {{ propsPiaCode }}</p>
		</p>
	  </div>
  
	  <div class="w-full lg:w-3/12 flex justify-end space-x-2 mt-3">
		<a
		  class="btn btn-primary btn-sm"
		  :disabled="!isPeriod(propsStart, propsEnd)"
		  :href="propsLink"
		  target="_blank"
		  >イープラス</a
		>
		<a
		  v-if="propsLawsonLink !== ''"
		  class="btn bg-cyan-500 text-gray-700 btn-sm"
		  :disabled="!isPeriod(propsStart, propsEnd)"
		  :href="propsLawsonLink"
		  target="_blank"
		  >ローソンチケット</a
		>
		<a
		  v-if="propsPiaLink !== ''"
		  class="btn bg-cyan-500 text-gray-700 btn-sm"
		  :disabled="!isPeriod(propsStart, propsEnd)"
		  :href="propsPiaLink"
		  target="_blank"
		  >チケットぴあ</a
		>
	  </div>
	</div>
  </template>
  
  <script>
  export default {
	props: [
	  "propsTitle",
	  "propsPeriod",
	  "propsStart",
	  "propsEnd",
	  "propsLink",
	  "propsLawsonCode",
	  "propsLawsonLink",
	  "propsPiaCode",
	  "propsPiaLink",
	],
	computed: {
	  isPeriod() {
		return (start, end) => {
		  let date = new Date();
		  let nowDate =
			date.getFullYear() +
			"-" +
			this.zeroPadding(date.getMonth() + 1, 2) +
			"-" +
			this.zeroPadding(date.getDate(), 2) +
			" " +
			this.zeroPadding(date.getHours(), 2) +
			":" +
			this.zeroPadding(date.getMinutes(), 2) +
			":" +
			"00";
  
		  return nowDate > start && nowDate < end;
		};
	  },
	},
	methods: {
	  zeroPadding(num, len) {
		return (Array(len).join("0") + num).slice(-len);
	  },
	},
  };
  </script>
  