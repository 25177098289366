<template>
	<div class="">
		<div class="w-full text-center py-2 text-accent">
			<h2 class="font-reggae text-4xl">ARTISTS</h2>
			<p class="text-white">出演アーティスト</p>
		</div>

		<!-- 出演アーティスト＠渋谷 -->
		<div class="flex flex-wrap items-center justify-between p-5 bg-primary">
			<p
				class="text-3xl lg:text-4xl font-zen text-secondary-content drop-shadow-md align-baseline"
			>
				2023.02.01
				<span class="text-2xl lg:text-3xl">[wed]</span>
			</p>
			<div class="w-full lg:w-1/2">
				<p
					class="text-2xl lg:text-4xl text-right font-reggae font-bold drop-shadow-lg"
				>
					渋谷 CLUB QUATTRO
				</p>
			</div>
		</div>

		<ArtistLineupShibuya />

		<!-- 出演アーティスト＠大阪 -->
		<div class="flex flex-wrap items-center justify-between p-5 bg-primary">
			<p
				class="text-3xl lg:text-4xl font-zen text-secondary-content drop-shadow-md align-baseline"
			>
				2023.02.04
				<span class="text-2xl lg:text-3xl">[sat]</span>
			</p>
			<div class="w-full lg:w-1/2">
				<p
					class="text-2xl lg:text-4xl text-right font-reggae font-bold drop-shadow-lg"
				>
					大阪 GORILLA HALL
				</p>
			</div>
		</div>

		<ArtistLineupOsaka />
	</div>
</template>

<script>
import ArtistLineupShibuya from "../Organisms/ArtistLineupShibuya.vue";
import ArtistLineupOsaka from "../Organisms/ArtistLineupOsaka.vue";

export default {
	components: {
		ArtistLineupShibuya,
		ArtistLineupOsaka,
	},
};
</script>
