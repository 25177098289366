<template>
	<div class="bg-neutral-content bg-opacity-20 py-5">
		<div class="w-full text-center py-2 text-accent">
			<h2 class="font-reggae text-4xl">HISTORY</h2>
			<p class="text-white">これまでのメスフェス</p>
		</div>
		<div class="flex flex-wrap justify-center lg:space-x-4 max-w-7xl">
			<div v-for="history in beforeHistorys" class="w-full lg:w-1/4 mt-5">
				<p class="font-reggae text-center text-3xl mb-2 text-primary">
					{{ history.year }}年
				</p>
				<img
					class="shadow-xl"
					:src="history.imageSrc"
					alt="♀フェス2019"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			beforeHistorys: [
				{
					year: 2019,
					imageSrc: require("@/assets/images/flyer/2019.jpg"),
				},
				{
					year: 2018,
					imageSrc: require("@/assets/images/flyer/2018.jpg"),
				},
				{
					year: 2017,
					imageSrc: require("@/assets/images/flyer/2017.jpg"),
				},
			],
		};
	},
};
</script>
