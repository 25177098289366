<template>
	<div class="flex flex-wrap m-auto max-w-7xl">
		<div v-for="artist in artistArray" class="p-2 w-full lg:w-1/3">
			<div class="card card-compact bg-base-300 rounded-lg shadow-xl">
				<figure>
					<img
						class="w-full"
						v-lazy="artist.imageSrc"
						:alt="artist.name"
					/>
				</figure>
				<div class="card-body">
					<h2 class="card-title text-accent font-reggae">
						{{ artist.name }}
					</h2>
					<div class="card-actions justify-end">
						<a
							v-if="artist.websiteLink"
							class="btn btn-primary btn-sm"
							:href="artist.websiteLink"
							target="_blank"
							>Web Site</a
						>
						<a
							v-if="artist.twitterLink"
							class="btn btn-secondary btn-sm"
							:href="artist.twitterLink"
							target="_blank"
							>Twitter</a
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			artistArray: [
				{
					name: "オメでたい頭でなにより",
					imageSrc: require("@/assets/images/artist/omedeta.jpg"),
					websiteLink: "https://www.omedeta.band/",
					twitterLink: "https://twitter.com/omedeta_japan",
				},
				{
					name: "四星球",
					imageSrc: require("@/assets/images/artist/SXC.jpg"),
					websiteLink: "http://su-xing-cyu.com/",
					twitterLink: "https://twitter.com/sxc__official",
				},
				{
					name: "どぶろっかーず",
					imageSrc: require("@/assets/images/artist/doburoku.jpg"),
					websiteLink: null,
					twitterLink: "https://twitter.com/doburockers_pr",
				},

				{
					name: "花団",
					imageSrc: require("@/assets/images/artist/HANADAN.jpg"),
					websiteLink: null,
					twitterLink: "https://twitter.com/hanadan1999",
				},
				{
					name: "ベッドイン",
					imageSrc: require("@/assets/images/artist/BEDIN2022A.jpg"),
					websiteLink: "http://bedin1919.chu.jp/",
					twitterLink: "https://twitter.com/bed_in1919",
				},
			],
		};
	},
};
</script>
