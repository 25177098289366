<template>
	<div class="bg-neutral-content bg-opacity-20">
		<div class="w-full text-center py-2 text-accent">
			<h2 class="font-reggae text-4xl">ACCESS</h2>
			<p class="text-white">アクセス</p>
		</div>

		<div class="w-full flex flex-wrap">
			<AccessMapShibuya class="w-full lg:w-1/2" />
			<AccessMapOsaka class="w-full lg:w-1/2" />
		</div>
	</div>
</template>

<script>
import AccessMapShibuya from "../Organisms/AccessMapShibuya.vue";
import AccessMapOsaka from "../Organisms/AccessMapOsaka.vue";

export default {
	components: {
		AccessMapShibuya,
		AccessMapOsaka,
	},
};
</script>
