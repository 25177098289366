<template>
	<div class="bg-neutral-content bg-opacity-20">
		<div class="w-full text-center py-2 text-accent">
			<h2 class="font-reggae text-4xl">TICKETS</h2>
			<p class="text-white">チケット情報</p>
		</div>

		<!-- チケット料金 -->
		<div class="w-full bg-gray-900 py-5">
			<p
				class="text-center text-3xl lg:text-5xl text-primary font-reggae font-bold"
			>
				♀フェス2023
			</p>
			<TicketShibuya class="mt-10" />

			<TicketOsaka class="mt-20" />

			<div class="w-full lg:w-2/3 m-auto mt-20 border p-3">
				<p class="mb-10">
					チケット料金<br />
					前売<span class="ml-3">￥5,800</span> / 当日<span
						class="ml-3"
						>￥6,300</span
					>
				</p>
				<!-- HP抽選先行販売 -->
				<TicketBuyGroup
					propsTitle="HP抽選先行販売"
					propsPeriod="12/9(金)12:00 ～ 12/18(日)23:59"
					propsStart="2022-12-09 12:00:00"
					propsEnd="2022-12-19 00:00:00"
					propsLink="https://eplus.jp/mesufes"
					propsLawsonLink=""
					propsPiaLink=""
				/>

				<!-- 一般発売 -->
				<TicketBuyGroup
					class="mt-5"
					propsTitle="一般発売 [東京]"
					propsPeriod="2023/1/7(土)10:00〜2023/1/31(火)23:59"
					propsStart="2023-01-07 10:00:00"
					propsEnd="2023-02-01 00:00:00"
					propsLink="https://eplus.jp/mesufes/"
					propsLawsonCode="----"
					propsLawsonLink=""
					propsPiaCode="232-980"
					propsPiaLink="https://w.pia.jp/t/mesfes2023/"
				/>
				<TicketBuyGroup
					class="mt-5"
					propsTitle="一般発売 [大阪]"
					propsPeriod="2023/1/7(土)10:00〜2023/2/3(火)23:59"
					propsStart="2023-01-07 10:00:00"
					propsEnd="2023-02-04 00:00:00"
					propsLink="https://eplus.jp/mesufes/"
					propsLawsonCode="----"
					propsLawsonLink=""
					propsPiaCode="232-980"
					propsPiaLink="https://w.pia.jp/t/mesfes2023/"
				/>
			</div>

			<!-- 注意事項 -->
			<div class="w-full lg:w-2/3 m-auto mt-10 p-5 border">
				<p class="text-lg pb-3">注意事項</p>
				<p class="text-sm">
					入場時別途ドリンク代あり<br />
					小学生以上有料<br />
					未就学児童入場不可<br />
					当日、学生証提示で￥1,000-キャッシュバック
				</p>
			</div>

			<!-- 問い合わせ -->
			<div class="mt-4 text-center text-sm">
				<p>(問）ADN STATE [平⽇12:00〜17:00]</p>
			</div>
		</div>
	</div>
</template>

<script>
import TicketOsaka from "../Organisms/TicketOsaka.vue";
import TicketShibuya from "../Organisms/TicketShibuya.vue";
import TicketBuyGroup from "../Molecules/TicketBuyGorup.vue";

export default {
	components: {
		TicketShibuya,
		TicketOsaka,
		TicketBuyGroup,
	},
};
</script>
