<template>
	<div class="w-full lg:w-2/3 m-auto">
		<p class="mt-5 text-center text-4xl lg:text-2xl lg:text-4xl font-bold">
			2023.2.4<span class="text-2xl lg:text-xl"> [sat] </span
			><br class="lg:hidden" />
			<span class="text-3xl lg:text-2xl lg:ml-3 text-accent font-bold"
				>大阪 GORILLA HALL</span
			>
		</p>

		<div
			class="w-full lg:w-2/3 text-gray-200 flex flex-wrap m-auto justify-center lg:space-x-10 mt-5"
		>
			<p class="w-4/5 lg:w-1/2">
				日程:<span class="ml-3 text-gray-300">2023年2月4日(土)</span
				><br />
				会場:<span class="ml-3 text-gray-300">大阪 GORILLA HALL</span
				><br />
				開場:<span class="ml-3 text-gray-300">15:00</span><br />
				開演:<span class="ml-3 text-gray-300">16:00</span>
			</p>
			<p class="w-4/5 lg:w-full m-auto text-md xl:text-lg">
				出演:<br />
				<span class="text-primary">
					打首獄門同好会 / 巨乳まんだら王国。 / 鬼龍院翔 / THE冠 /
					バックドロップシンデレラ
				</span>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			firstPreOrder: {
				start: "2022-12-01 20:00:00",
				end: "",
			},
		};
	},
	computed: {
		isPeriod() {
			return (start, end) => {
				let date = new Date();
				let nowDate =
					date.getFullYear() +
					"-" +
					this.zeroPadding(date.getMonth() + 1, 2) +
					"-" +
					this.zeroPadding(date.getDate(), 2) +
					" " +
					this.zeroPadding(date.getHours(), 2) +
					":" +
					this.zeroPadding(date.getMinutes(), 2) +
					":" +
					"00";
				return nowDate > start && nowDate < end;
			};
		},
	},
	methods: {
		zeroPadding(num, len) {
			return (Array(len).join("0") + num).slice(-len);
		},
	},
};
</script>
