<template>
	<div>
		<TopImage />
		<TicketGroup class="my-24" />
		<ArtistLineupGroup class="" />
		<AccessMapGroup class="mt-24" />
		<OldFlyers class="mt-24" />
		<Footer class="mt-16 pb-5" />
	</div>
</template>

<script>
import TopImage from "@/components/TopImage.vue";
import TicketGroup from "@/components/Templates/TicketGroup.vue";
import ArtistLineupGroup from "@/components/Templates/ArtistLineupGroup.vue";
import AccessMapGroup from "@/components/Templates/AccessMapGroup.vue";
import Footer from "@/components/Organisms/Footer.vue";
import OldFlyers from "@/components/Organisms/OldFlyers.vue";

export default {
	components: {
		TopImage,
		TicketGroup,
		ArtistLineupGroup,
		AccessMapGroup,
		OldFlyers,
		Footer,
	},
};
</script>
